import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import labellise from '../react/controllers/labellise.png'
import trajectory from '../react/controllers/trajectoire.png'
import 'leaflet.pattern';

const turf = require('@turf/turf');

const language = (window.location.href.indexOf('/en/') !== -1) ? 'en' : 'fr';
const params = `&accept-language=${language}&format=jsonv2&polygon_geojson=1&polygon_threshold=0.005`;
var inputList = []
var locationJSONData = {
  mainLocation : {},
  locations: []
}

export const getGeoData = async (location)  => {
  const response = await fetch(`https://nominatim.openstreetmap.org/search.php?q=${location}${params}`);
  if (!response.ok) {
    throw new Error('Erreur lors de la requête');
  }

  const data = await response.json();

  return data[0];
}

export function combineMultiPolygons(multiPolygon1, multiPolygon2) {
  const combinedMultiPolygon = turf.union(multiPolygon1, multiPolygon2);
  return combinedMultiPolygon;
}

async function autocompleteAddress(inputId, dataInputId, isMainLocation, index) {
    
    var input = document.getElementById(inputId);
    var inputData = document.getElementById(dataInputId);
    var suggestionList = document.createElement('ul');
    suggestionList.setAttribute('id', 'suggestion-list');
    input && input.parentNode.appendChild(suggestionList);

    var apiUrl = 'https://nominatim.openstreetmap.org/search?format=json&q=';

    input && input.addEventListener('input', function() {
      var query = input.value;
      if (query.length > 2) {
        fetch(apiUrl + query + '&accept-language=' + language)
          .then(response => response.json())
          .then(data => {
        
            suggestionList.innerHTML = '';

            data.forEach(function(item) {
              var listItem = document.createElement('li');
              listItem.textContent = item.display_name;
              suggestionList.appendChild(listItem);

              listItem.addEventListener('click', async function() {
                suggestionList.innerHTML =  listItem.textContent; 
                suggestionList.style = "color: green";
                const geoData = await getGeoData(listItem.textContent)
                if (isMainLocation){
                  locationJSONData.mainLocation=geoData;
                }else{
                  if (index > locationJSONData.locations.length-1){
                    locationJSONData.locations.push(geoData.geojson);
                  }else {
                    locationJSONData.locations[index] = geoData.geojson;
                  }
                }
                inputData.value =  JSON.stringify(locationJSONData)
                const workshopType = document.getElementById('DataWorkshop_workshopType')
                workshopType && addLocationDataToMap(locationJSONData, map, workshopType.value)
                });
            });
          })
          .catch(error => console.error('Erreur lors de la recherche d\'adresse:', error));
      } else {
        suggestionList.innerHTML = '';
      }
    });
  }


const observer = new MutationObserver(() => {
  const inputs = document.querySelectorAll('input[id^="DataWorkshop_locations_"]');
  inputs.forEach(input => {
    if (!inputList.find(obj => obj.id === input.id)) {
      inputList.push({id : input.id, active: false})
    }
  })
  
  inputList.forEach((input, index) => {
    if (!input.active){
      document.addEventListener('change', autocompleteAddress(input.id,'DataWorkshop_mainLocationData', false, index));
      input.active = true;
    }
  })
});

export const labelliseIcon = L.icon({
  iconUrl: labellise,
  iconAnchor: [25, 50],
  iconSize: [50, 50]   ,
});

export const trajectoryIcon = L.icon({
iconUrl: trajectory,
iconAnchor: [25, 50],   
iconSize: [50, 50],
});


export const patternOptions = {
  height: 10,
  width: 10,
  color: "#1abc9c",
  spaceColor: "#fff",
  weight: 2,
  spaceWeight: 10,
  angle: 135,
  opacity: 0.55,
  spaceOpacity: 0.45
};

const customPopupContent = () => {
  return(`
  <div class='map_popup'>
    <div class='popup_header'>
      
      <h2 class='title'><img src=${labellise} class='header_icon' style="width: 4vw"/>
        ${language === 'fr' ? 'Nom développé' : 'Extended name'}
      </h2>
      <div class='acronym'>${language === 'fr' ? `Sigle` : 'Acronym'}</div>
    </div>

    <p class='institutions'>${language === 'fr' ? 'Établissements partenaires' : 'Partner institutions' } :</p>
    <ul>
        <li key={index}>
          <a href="" target="_blank" rel="noopener noreferrer">Institution</a>
        </li>
    <div class='url'>
      <a style='margin: 10px' href="" class="fr-tag fr-fi-arrow-right-line fr-tag--icon-right" target="_blank">
        ${language === 'fr' ? "En savoir plus" : "More informations"}
      </a>
    </div>

    </ul>
  </div>`
  )
};

const addLocationDataToMap = (locationData, map, workshopType) => {

  //reset map
  map.eachLayer(function(layer) {
    if (layer instanceof L.Marker) {
        map.removeLayer(layer);
    }
  });

  map.eachLayer(function(layer) {
    if (layer.getLayers && layer.getLayers().length > 0) {
        var subLayers = layer.getLayers();
        if (subLayers[0] instanceof L.Polygon) {
            map.removeLayer(layer);
        }
    }
  });

  //adding main location
  L.marker([locationData.mainLocation.lat, locationData.mainLocation.lon],{icon : workshopType.value === 'Labellisé' ? labelliseIcon : trajectoryIcon}).addTo(map).bindPopup(customPopupContent())
  
  var stripes = new L.StripePattern(patternOptions);
  stripes.addTo(map);

  let locations = {
    type: "MultiPolygon",
    coordinates: []
  };

  locationData.locations.forEach(location => {
      locations = combineMultiPolygons(locations, location);
  })

  
  let polygon = L.geoJSON(locations, {
    color: '#1abc9c', 
    fillColor: '#1abc9c', 
    fillOpacity: 0.8,
    fillPattern: workshopType === 'Sur la trajectoire' && stripes,
    crs: L.CRS.EPSG4326
  })
  // adding polygon
  polygon.addTo(map).bindPopup(customPopupContent());

}

var map;

const mapPreview = async () => {
  const sectionElement = document.getElementById('main');
  const mapDiv = document.createElement('div');
  mapDiv.id = 'map';
  mapDiv.style.width = '100%';
  mapDiv.style.height = '500px';
  mapDiv.style.marginBottom = '20px';
  mapDiv.style.marginTop = '20px';

  sectionElement.appendChild(mapDiv);
  map = L.map('map').setView([46.603354, 1.888334], 5, { crs: L.CRS.EPSG4326 }); 
  L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
  }).addTo(map);

  // Adding marker if main location and workshop type filled
  const workshopType = document.getElementById('DataWorkshop_workshopType')
  const mainLocationInput = document.getElementById('DataWorkshop_mainLocationData')
  const mainLocationValue = document.getElementById('DataWorkshop_locationValue').value

  // Init JSON variable if geo data already in database
  if (mainLocationInput.value !== "") {
    locationJSONData = JSON.parse(mainLocationInput.value);
    console.log("Getting stored location data : ", locationJSONData);
    addLocationDataToMap(locationJSONData, map, workshopType.value)
  }

  // Init main location JSON if input
  if (Object.keys(locationJSONData.mainLocation).length === 0 && mainLocationValue !== ""){
    const data = await getGeoData(mainLocationValue);
    locationJSONData.mainLocation = data;
    mainLocationInput.value = JSON.stringify(locationJSONData)
    console.log("Init main location :", locationJSONData);
  }

  // Init location list JSON if input
  if (locationJSONData.locations.length <= 0){
    const inputs = document.querySelectorAll('input[id^="DataWorkshop_locations_"]');
    await inputs && inputs.forEach(async input =>  {
      const data = await getGeoData(input.value);
      locationJSONData.locations.push(data.geojson)
      mainLocationInput.value = JSON.stringify(locationJSONData)
    })
    console.log("Init location list ...", locationJSONData)
  }
  
}

//Adding map preview in back-office
if (document.getElementById('edit-DataWorkshop-form') || document.getElementById('new-DataWorkshop-form')){
  mapPreview();
}

try {
  document.addEventListener('DOMContentLoaded', autocompleteAddress('DataWorkshop_locationValue', 'DataWorkshop_mainLocationData', true, 0));

  observer.observe(document.body, {
    childList: true,
    subtree: true
  });

} catch (e) {
    console.warn('Map API error.')
}
